<script>
import DefaultMixin from "@/components/leiloes/mixin"
import {ErpInput} from 'uloc-vue-plugin-erp'
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"

export default {
  name: 'Live',
  inject: ['page', 'erplayout'],
  mixins: [DefaultMixin],
  components: {
    ERow,
    ECol,
    ErpInput
  },
  data() {
    return {
      leilao: this.page.leilao,
      live: this.page.leilao.video
    }
  },
  computed: {},
  mounted() {
    this.page.setActive('live')
    // document.querySelector('.u-erp-layout-container').classList.add('leilao-telao-seletor')
  },
  destroyed() {
    // document.querySelector('.u-erp-layout-container').classList.remove('leilao-telao-seletor')
  },
  methods: {
    getVideoEmbedUrl(url) {
      const isYouTubeUrl = url.includes('youtube.com') || url.includes('youtu.be');

      // Se for uma URL do YouTube, aplicar a lógica de transformação
      if (isYouTubeUrl) {
        // Expressão regular para capturar o ID do vídeo do YouTube
        const regExp = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|v\/|live\/|shorts\/)|youtu\.be\/)([\w-]{11})(?:.*)/;
        const match = url.match(regExp);

        // Se a URL for válida e contiver um ID de vídeo
        if (match && match[1]) {
          const videoId = match[1];
          return `https://www.youtube.com/embed/${videoId}?controls=0&autoplay=1&loop=1&rel=0&showinfo=0`;
        }
        return url; // Retorna a URL original se não conseguir extrair o ID
      }

      // Se não for uma URL do YouTube, retorna a URL exata
      return url;
    },
    testUrl() {
      let live = this.live
      let url = this.getVideoEmbedUrl(this.live)
      console.log(url)
      if (!url) {
        alert('URL Inválida.')
        return
      }
      this.leilao.video = url
      return url
    },
    save() {
      this.comunicatorClass.defineUrlLive(this.leilao.id, this.testUrl()) // @TODO: Atualmente estamos somente preparados para Youtube.
          .then((response) => {
            console.log(response.data)
          })
          .catch(({response}) => {
            this.alertApiError(response)
          })
    }
  },
  meta: {
    title: 'Transmissão do Leilão',
    name: 'Transmissão do Leilão'
  }
}
</script>

<template>
  <div class="wrapper">
    <div class="text-center">
      <div class="font-bold m-b">Vídeo atual:</div>
      <div v-if="leilao.video">
        <iframe width="70%" height="300" :src="leilao.video"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; loop"
                allowfullscreen></iframe>
      </div>
      <div class="m-t" v-else>
        Nenhuma transmissão informada.
      </div>

      <div style="display: block; max-width: 800px; margin: 40px auto" class="m-t">
        <e-row>
          <e-col class="flex items-center" style="max-width: 200px">Atualizar transmissão:</e-col>
          <e-col>
            <erp-input size="2" v-model="live"/>
          </e-col>
          <e-col>
            <e-btn @click="testUrl" label="Testar link"/>
            <e-btn @click="save" class="m-l" label="Atualizar transmissão"/>
          </e-col>
        </e-row>
        <e-row class="m-t-xxl">
          <e-col>Utilize os botões abaixo caso tenha adaptado no site a transmissão global</e-col>
        </e-row>
      </div>
    </div>
  </div>
</template>

<style src="../transmissao/assets/global.styl" lang="stylus"/>
